export default {
  SPOT_CATEGORY_LIST: "Category list",
  ADD_PRICING_RULES: "Add Pricing Rule",
  PRICING_RULES_ADDED: "Pricing Rule added",
  EDIT_PRICING_RULES: "Update Pricing Rule",
  PRICING_RULES_UPDATED: "Pricing Rule updated",
  DELETE_THIS_PRICING_RULES: "delete Pricing Rule ?",
  PRICING_RULES_DELETED: "Pricing Rule deleted",
  PRICING_RULES_NAME: "Pricing Rule name",
  SPOT_FORM_TYPE: "Form type",
  SPOT_FORM_TYPE_DEFAULT: "Default",
  SPOT_FORM_TYPE_LAND: "Land",
  SPOT_FORM_TYPE_PROPERTY: "Property",
  VIEW_PRICING_RULES: "Pricing Rule details",

  SAME_SPOT_CATEGORIES:
    'One or more spot categories already exist with the name {name}. Is this the same Pricing Rule? Click "Continue" to save if so, otherwise click "Cancel."',
};
